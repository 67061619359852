import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Container, Row, Col, Modal } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Tabcomponent.css"
import {Menus} from "./utils/constants"
import { useNavigate } from "react-router-dom";
import VideoDuration from './Videoduration';
import { useParams } from 'react-router-dom';

export default function Recommend(props: any) {
  const [videorecmenddata, setVideoRecmendData] = useState<any>([]);
  const [vidorecmendshow, setVideoRecmendShow] = useState(false);
  const [videomodaldata, setVideoModalData] = useState<any>([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [durations, setDurations] = useState({});
  const [citations, setCitations] = useState("");
  const { menu, submenu } = useParams();
  const [activeKey, setActiveKey] = useState('recommended-videos'); // Default active key
  
  const baseURL = process.env.REACT_APP_API_URL || "http://18.205.155.104:8000";
  
  useEffect(() => {
      if(menu === undefined) {
        const recommendData =  props.videoMetadata.filter((data: any) => data.category.title === "Breast Cancer");
        setVideoRecmendData(recommendData)
      } else {
        const recommendData =  props.videoMetadata.filter((data: any) => data.category.title === submenu);
        setVideoRecmendData(recommendData)
      }
      if(props){
      props.videoData.map((item: any, i: any) =>  {
        setCitations(item.citations);
      });
      if(props.videoTrendData !== ""){
        const vidoTitle = props.videoTrendData === "Home" ? "Breast Cancer" : props.videoTrendData;
        const recommendData =  props.videoMetadata.filter((data: any) => data.category.title === vidoTitle);
        setVideoRecmendData(recommendData);
      }
    }
  }, [props.videoData]);


 
  // useEffect(() => { 
  //   if(props){
  //     props.videoData.map((item: any, i: any) =>  {
  //       setCitations(item.citations);
  //     });
  //     setVideoRecmendData(props.videoTrendData)
  //   }
  // }, [props.videoTrendData, citations]);

  const carouselresponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const handleVideoModalClose = () => { setVideoRecmendShow(false);}

  const handleVideoModalShow = (videoData: any) => {
      setVideoRecmendShow(true);
      // console.log(videoData);
      setVideoModalData([videoData])
  };

  const handleVideoClick = () => {
      setIsPlaying(!isPlaying);
  };
 
  // let navigate = useNavigate(); 
  // const handleroutechange = (submenu:any) =>{ 
  //   // console.log(submenu);
  //   const filteredmenu: any = Menus.find(menu => menu.menuitem.some(item => item.name === submenu));
  //   const mainmenu = filteredmenu.menutitle
  //   let path = `/understand/${mainmenu}/${submenu}`; 
  //   navigate(path);
  //   window.scroll(0,0);
  // }

  const sendRecTrendVideo  = (videoid : any , videotitle:any) => { 
    // console.log(videoid);
    props.getRecTrendVideo(videoid, videotitle);
  };
  const getCitations  = (citations : any) => { 
    // console.log(videoid);
    setCitations(citations);
  };

  return(
    <div className="recommend-tabs">
      <Container>
        <Tabs activeKey={activeKey} onSelect={(k: any) => setActiveKey(k)} id="fill-tab-example" className="mb-3" fill>
            <Tab eventKey="recommended-videos" title="Recommended Videos">
              <Carousel responsive={carouselresponsive} className="mt-5">
                {videorecmenddata.map((item: any, i: any) => (
                  <div key={i} style={{position:"relative"}}>
                      <img src={`${item.poster_image}`}  className="img-fluid" 
                            style={{width:"260px", borderRadius:"5px", cursor:"pointer"}} alt={""} 
                            onClick={()=>{sendRecTrendVideo(item.id, item.category.title);
                            getCitations(item.citations)}}/>
                      <div className="recvideo-title mt-3">{item.title}</div>
                      <div className="recvideo-duration mt-1">
                        <VideoDuration getvideourl={item.video}/>
                      </div>
                      {/* <div className="playicon-section" onClick={()=>handleVideoModalShow(item)}></div> */}
                  </div>
                ))}
              </Carousel>
            </Tab>
            <Tab eventKey="citations" title="Citations">
                <div className="citation-content-section">
                  {citations}
                </div>
            </Tab>
        </Tabs>
      </Container>
      <div className="videomodal-section">
                <Modal show={vidorecmendshow} onHide={handleVideoModalClose} backdrop="static" size="xl" keyboard={false} centered>
                    <Modal.Header closeButton>
                        
                    </Modal.Header>
                    <Modal.Body style={{padding:"24px 14px 30px 14px"}}>
                        <Container>
                        {videomodaldata && videomodaldata.map((item: any, index: any) => (
                            <div>
                              <Row className="mb-2">
                                  <h4><b>{item.title}</b></h4>
                              </Row>
                              <Row>
                              <video controls autoPlay={true} className="embed-responsive-item"
                                        onClick={handleVideoClick} controlsList="nodownload">
                                        <source src={`${item.video}`} type="video/mp4" />
                                    </video>
                              </Row>
                            </div>
                          ))}
                        </Container>
                    </Modal.Body>
                </Modal>
            </div>
    </div>
  )
}