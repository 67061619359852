import React, { useState, useEffect } from 'react';
import './Home.css';
import './videolibrary.css';
import { Row, Col, Breadcrumb, Container, Modal,Button, Nav } from 'react-bootstrap';
import apiService from './services/apiservice';
import {Menus} from "./utils/constants"
import { useNavigate } from "react-router-dom";
import { ReactComponent as LikeIcon } from './assets/likeicon.svg';
import { ReactComponent as ShareIcon } from './assets/shareicon.svg';
import { ReactComponent as ArrowdownIcon } from './assets/arrowndown_black.svg';
import { ReactComponent as ArrowUpIcon } from './assets/arrowup_black.svg';
import ShareModal from './sharemodal';
import VideoDuration from './Videoduration';

export default function VideoLibrary() {
    const [videoMetadata, setVideoMetaData] = useState<any>([]);
    const [vidomodalshow, setVideoModalShow] = useState(false);
    const [videomodaldata, setVideoModalData] = useState<any>([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLikeClicked, setIsLikeClicked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [videourl, setVideoUrl] = useState();
    const [videoId, setvideoId] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [filteredVideoData, setFilteredVideoData] = useState<any>([]);
    const [categoryTitle, setCategoryTitle] = useState<any>("");
    const [activeLink, setActiveLink] = useState(null);

    const baseURL = process.env.REACT_APP_API_URL || "http://18.205.155.104:8000";

//     let navigate = useNavigate(); 
//     const handleroutechange = (submenu:any) =>{ 
//     // console.log(submenu);
//     const filteredmenu: any = Menus.find(menu => menu.menuitem.some(item => item.name === submenu));
//     const mainmenu = filteredmenu.menutitle
//     let path = `/understand/${mainmenu}/${submenu}`; 
//     navigate(path);
//     window.scroll(0,0);
//   }
    useEffect(() => {
        const getVideoMetaData = async () => {
            try {
                const response = await apiService.get('/api/video_metadata');
                if (response) {
                    // console.log('Data from API:', response);
                    const filteredresponse = response.data.filter((data: any) => data.category.title != "About Us");
                    setVideoMetaData(filteredresponse);
                    setFilteredVideoData(filteredresponse);
                }
            } catch (error: any) {
                console.error('Error fetching data:', error.message);
            }
        }
        getVideoMetaData();

    }, [])

    const handleLike = async (videoId: any) => {
        // setisLoading(true);
        setIsLikeClicked(true);
        let PostData = {
            id: videoId,
            action:"likes"
        }
        try {
            const response = await apiService.put('/api/video_metadata', PostData);
            if (response) {
                // console.log("response:", response);
                // setIsLikeClicked(true);
            }
        } catch (error: any) {
            console.error('Error fetching data:', error.message);
            // setisLoading(false);
        }
    };
    const handleVideoModalShow = (videoData: any) => {
        setVideoModalShow(true);
        // console.log(videoData);
        setVideoModalData([videoData])
    };
    const handleVideoClick = () => {
        setIsPlaying(!isPlaying);
    };
    const handleVideoModalClose = () => { setVideoModalShow(false);setIsLikeClicked(false);}
    const handleShowModal = () => setShowModal(true);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const getShareVideoUrl = (videoData: any) => {
        setVideoUrl(videoData.video);
        setvideoId(videoData.id);
    };
    const toggleCitations = () => {
        setIsOpen(!isOpen);
    };

    const getVideocatgoryMenu = (categorymenu: any, activeindex: any) => {
        if (categorymenu === "All Videos") {
            setFilteredVideoData(videoMetadata);
        } else {
            const filteredCatgory = videoMetadata.filter((data: any) => data.category.menu == categorymenu);
            setFilteredVideoData(filteredCatgory);
        }
        setCategoryTitle(categorymenu);
        setActiveLink(activeindex);

    };
    return (
        <div className="video-library-section">
            <Container>
                <Row>
                    <h2 className="mt-4 mb-4">Video Library</h2>
                </Row>
                <Row className="mb-4">
                    <div className="videolib-link">
                        <ul>
                            {/* <li className={activeLink === 0 ? "active" : ""}
                                onClick={() => getVideocatgoryMenu("Autoimmune & Inflammatory Diseases" , 0)}>
                                    Autoimmune & Inflammatory Diseases
                            </li> */}
                            <li className={activeLink === 1 ? "active" : ""} onClick={() => getVideocatgoryMenu("Cancer", 1)}>
                                    Cancer
                            </li>
                            {/* <li className={activeLink === 2 ? "active" : ""} onClick={() => getVideocatgoryMenu("Cardiovascular Diseases", 2)}>
                                    Cardiovascular Diseases
                            </li>
                            <li className={activeLink === 3 ? "active" : ""} onClick={() => getVideocatgoryMenu("Geriatric Health", 3)}>
                                    Geriatric Health
                            </li>
                            <li className={activeLink === 4 ? "active" : ""} onClick={() => getVideocatgoryMenu("Neurological Diseases & Conditions", 4)}>
                                    Neurological Diseases & Conditions
                            </li>
                            <li className={activeLink === 5 ? "active" : ""} onClick={() => getVideocatgoryMenu("Urinary & Reproductive Health", 5)}>
                                    Urinary & Reproductive Health
                            </li>
                            <li className={activeLink === 6 ? "active" : ""} onClick={() => getVideocatgoryMenu("Respiratory Conditions", 6)}>
                                    Respiratory Conditions
                            </li>
                            <li className={activeLink === 7 ? "active" : ""} onClick={() => getVideocatgoryMenu("How Therapies Work", 7)}>
                                    How Therapies Work
                            </li> */}
                            <li className={activeLink === 8 ? "active" : ""} onClick={() => getVideocatgoryMenu("Public Health Topics", 8)}>
                                    Public Health Topics
                            </li>
                            <li className={activeLink === 9 ? "active" : ""} onClick={() => getVideocatgoryMenu("All Videos", 9)}>
                                    All Videos
                            </li>
                        </ul>

                    </div>
                </Row>
                <Row>
                    {filteredVideoData.map((item: any, index: any) => (
                        <Col xs={12} sm={12} md={3} lg={4} xl={4} className="mt-3" style={{ position: "relative" }} key={index}>
                            {item.tags.map((tagItem: any, i: any) => (
                                <div className="trend-video-tag mb-3" key={i}>{tagItem.title}</div>

                            ))
                            }
                            <div className="trend-video-contain img-fluid">
                                <img src={`${item.poster_image}`} className="img-fluid"
                                    style={{ width: "350px", borderRadius: "5px", cursor: "pointer" }} alt={""}
                                    // onClick={()=>handleroutechange(item.category.title)}
                                    onClick={() => handleVideoModalShow(item)}
                                />
                            </div>
                            <div className="trend-video-title mt-3">{item.title}</div>
                            <div className="trend-video-duration mt3">
                                <VideoDuration getvideourl={item.video}/>
                            </div>
                            {/* <div className="trend-playicon-section"></div> */}
                        </Col>
                    ))}
                    {/* // : <div>
                    //     <h3>No videos Found</h3>
                    //     <span>Click here to view <a href="#" onClick={() => getVideocatgoryMenu("All Videos")}>
                    //         All Videos</a>
                    //     </span>
                    // </div> */}
                </Row>
            </Container>
            <div className="videomodal-section">
                <Modal show={vidomodalshow} onHide={handleVideoModalClose} backdrop="static" size="xl" keyboard={false} centered>
                    <Modal.Header closeButton>
                        
                    </Modal.Header>
                    <Modal.Body style={{padding:"24px 14px 30px 14px"}}>
                        <Container>
                        {videomodaldata && videomodaldata.map((item: any, index: any) => (
                            <div key={index}>
                                <Row className="mb-3">
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <h4><b>{item.title}</b></h4>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <div className="like-section mb-2">
                                            <button className={`socialicon-btn ${isLikeClicked ? 'clicked' : ''}`}
                                                type="button" style={{ marginRight: "16px" }}
                                                onClick={() => handleLike(item.id)}>
                                                <LikeIcon style={{ marginRight: "10px", marginBottom: "3px" }} />Like
                                            </button>
                                            <button className="socialicon-btn" type="button"
                                                onClick={() => { handleShowModal(); getShareVideoUrl(item) }}>
                                                <ShareIcon style={{ marginRight: "10px", marginBottom: "3px" }} />Share
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <video controls autoPlay={true} className="embed-responsive-item"
                                        onClick={handleVideoClick} controlsList="nodownload">
                                        <source src={`${item.video}`} type="video/mp4" />
                                    </video>
                                </Row>
                                <div onClick={toggleCitations} className="citations-section mt-5 mb-3">
                                    <h5 style={{ margin: 0, padding: "4px 10px" }}><b>
                                        Citations</b>
                                        {isOpen === true ?
                                            <ArrowUpIcon style={{ float: "right" }} /> :
                                            <ArrowdownIcon style={{ float: "right" }} />
                                        }
                                    </h5>

                                    {isOpen && (
                                        <div className="scroll-section">
                                            {item.citations}
                                        </div>
                                    )}
                                </div>
                            </div>
                            
                          ))}
                        </Container>
                    </Modal.Body>
                </Modal>
            </div>
            <div className="sharemodal-section">
                <ShareModal show={showModal} handleClose={handleCloseModal} videourl={videourl} videoId={videoId} />
            </div>
        </div>
    )

}