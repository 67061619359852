import React, { useState, useEffect } from 'react';
import './Trendvideo.css';
import { Container , Row, Col, Modal } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import {Menus} from "./utils/constants"
import VideoDuration from './Videoduration';

export default function TrendVideo(props: any) {
    const [videotrenddata, setVideoTrendData] = useState<any>([]);
    const [vidotrendshow, setVideoTrendShow] = useState(false);
    const [videomodaldata, setVideoModalData] = useState<any>([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const baseURL = process.env.REACT_APP_API_URL || "http://18.205.155.104:8000";

    useEffect(() => {
        const trendVideoData =  props.videoMetadata.filter((data: any) => data.trending === true);
        // console.log(trendVideoData);
        setVideoTrendData(trendVideoData);
     
    }, []);

const handleVideoModalClose = () => { setVideoTrendShow(false);}

  const handleVideoModalShow = (videoData: any) => {
        setVideoTrendShow(true);
        // console.log(videoData);
        setVideoModalData([videoData])
  };

  const handleVideoClick = () => {
      setIsPlaying(!isPlaying);
  };

  let navigate = useNavigate(); 
  
  const handleroutechange = (submenu:any) =>{ 
    // console.log(submenu);
    const filteredmenu: any = Menus.find(menu => menu.menuitem.some(item => item.name === submenu));
    const mainmenu = filteredmenu.menutitle
    let path = `/understand/${mainmenu}/${submenu}`; 
    navigate(path);
    window.scroll(0,0);
  }

  const sendRecTrendVideo  = (videoid : any, videotitle: any) => { 
    // console.log(videoid);
    props.getRecTrendVideo(videoid, videotitle);
  };

    return(
        <div style={{background:"#F8F9FA"}}>
            <Container>
                <Row>
                    <div className="trend-video-header">Trending Videos</div>
                </Row>
                <Row>
                    {videotrenddata.map((item: any, index: any) => (
                        <Col xs={12} sm={12} md={3} lg={4} xl={4} className="mt-3" style={{position:"relative"}} key={index}>
                             {item.tags.map((tagItem: any, i: any) => ( 
                                <div className="trend-video-tag mb-3" key={i}>{tagItem.title}</div>

                             ))
                            }
                            <div className="trend-video-contain img-fluid">
                                <img src={`${item.poster_image}`}  className="img-fluid"
                                style={{width:"350px", borderRadius:"5px", cursor:"pointer"}} alt={""}  
                                onClick={()=>{sendRecTrendVideo(item.id, item.category.title)}}/>
                            </div>
                            <div className="trend-video-title mt-3">{item.title}</div>
                            <div className="trend-video-duration mt3">
                                <VideoDuration getvideourl={item.video}/>
                            </div>
                            {/* <div className="trend-playicon-section" onClick={()=>handleVideoModalShow(item)}></div> */}
                        </Col>
                    ))}
                </Row>
                <Row className="pt-4 pb-5">
                    <div className="seefull-video-btn-section">
                        <a href="/explore/See Our Full Library">
                            <button className="seefull-video-btn" type="button">See Full Video Library</button>
                        </a>
                    </div>
                </Row>
            </Container>
            <div className="videomodal-section">
                <Modal show={vidotrendshow} onHide={handleVideoModalClose} backdrop="static" size="xl" keyboard={false} centered>
                    <Modal.Header closeButton>
                        
                    </Modal.Header>
                    <Modal.Body style={{padding:"24px 14px 30px 14px"}}>
                        <Container>
                        {videomodaldata && videomodaldata.map((item: any, index: any) => (
                            <div>
                              <Row className="mb-2">
                                  <h4><b>{item.title}</b></h4>
                              </Row>
                              <Row>
                              <video controls autoPlay={true} className="embed-responsive-item"
                                        onClick={handleVideoClick}>
                                        <source src={`${item.video}`} type="video/mp4" />
                                    </video>
                              </Row>
                            </div>
                          ))}
                        </Container>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}