import React, { useState, useEffect } from 'react';
import './About.css';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import ProfileJoanImg from "./assets/Joan-fancy.png";
import ProfileAmiImg from "./assets/ami-ehlenberger 1.png";
import ProfileLisaImg from "./assets/lisa-m 1.png";
import ProfileChrisImg from "./assets/chris-francy 1.png";
import ProfileHannhImg from "./assets/hannah-mcGary 1.png";
import ProfileKathImg from "./assets/katherine-na 1.png";
import ProfileDadaImg from "./assets/dada-honde-denior-director 1.png";
import AboutPosterImg from "./assets/aboutus-poster.png"
import { ReactComponent as LikeIcon } from './assets/likeicon.svg';
import { ReactComponent as ShareIcon } from './assets/shareicon.svg';
import Sponser from './Sponser';
import apiService from './services/apiservice';
import ShareModal from './sharemodal';

export default function About() {
    const [isPlaying, setIsPlaying] = useState(false);
    const [videoAboutusdata, setVideoAboutusData] = useState<any>([]);
    const [isLikeClicked, setIsLikeClicked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [videourl, setVideoUrl] = useState();
    const [videoId, setvideoId] = useState();

    const baseURL = process.env.REACT_APP_API_URL || "http://18.205.155.104:8000";
    useEffect(() => {
        const getVideoMetaData = async () => {
            try {
                const response = await apiService.get('/api/video_metadata');
                if (response) {
                    // console.log('Data from API:', response);
                    let filteredData = response.data.filter((data: any) => data.category.title === "About Us");
                    // console.log(filteredData);
                    setVideoAboutusData([filteredData[0]]);
                }
            } catch (error: any) {
                console.error('Error fetching data:', error.message);
            }
        }
        getVideoMetaData();

    }, [])

    const handleLike = async (videoId: any) => {
        // setisLoading(true);
        setIsLikeClicked(true);
        let PostData = {
            id: videoId,
            action:"likes"
        }
        try {
            const response = await apiService.put('/api/video_metadata', PostData);
            if (response) {
                // setIsLikeClicked(true);
            }
        } catch (error: any) {
            console.error('Error fetching data:', error.message);
            // setisLoading(false);
        }
    };
    const handleVideoClick = () => {
        setIsPlaying(!isPlaying);
    };

    const handleShowModal = () => setShowModal(true);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const getShareVideoUrl = (videoData: any) => {
        setVideoUrl(videoData.video);
        setvideoId(videoData.id);
    };
    return (
        <div className="container-section pt-3">
        <Container>
            <Breadcrumb className="breadcrumb-section">
                <Breadcrumb.Item href="/" className="breadcrumb-active">Home</Breadcrumb.Item>
                <Breadcrumb.Item className="breadcrumb-active">About Us</Breadcrumb.Item>
            </Breadcrumb>
            { videoAboutusdata && videoAboutusdata.map((item: any, index: any) => (
            <div className="aboutus-section">
                <h2 className="mb-4 mt-5 fw-bold">{item.title}</h2>
                <p className="mb-4 mt-4"> {item.title === item.description ? "" : item.description }</p>
                {/* <Row className="mt-4 mb-4">
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="author-name">
                            <img src={`${item.author.profile_picture}`} className="profile-img" />
                            <span style={{ position: "relative", left: "12px" }}>
                                <b>{item.author.name}, {item.author.description}</b>
                            </span>
                        </div>
                    </Col> */}
                    {/* <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="like-section mb-2">
                            <button className={`socialicon-btn ${isLikeClicked ? 'clicked' : ''}`}
                                    type="button" style={{ marginRight: "16px" }} onClick={() => handleLike(item.id)}>
                                <LikeIcon style={{ marginRight: "10px", marginBottom: "3px" }}/>Like
                            </button>
                            <button className="socialicon-btn" type="button"
                                onClick={() => {handleShowModal(); getShareVideoUrl(item)}}>
                                <ShareIcon style={{ marginRight: "10px", marginBottom: "3px" }} />Share
                            </button>
                        </div>
                    </Col> */}
                {/* </Row> */}
                <Row className="mt-4 mb-4">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="embed-responsive">
                            {/* <img src={VideoImg} className="img-fluid" alt="Responsive image" /> */}
                            <video controls autoPlay={false} className="embed-responsive-item" controlsList="nodownload"
                                onClick={handleVideoClick} poster={`${item.poster_image}`}>
                                <source src={`${item.video}`} type="video/mp4" />
                            </video>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="missionmsg-section">
                            <p className="mt-4 mb-4" style={{fontSize:"22px"}}>
                                At Understand the Science, our mission is to provide easy-to-understand 
                                 education on crucial medical topics for empowering healthcare decisions.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-5 mb-4">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="ourteam-title">
                            <h2 className="fw-bold">Our Team</h2>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4 ourteam-section" gutter={2}>
                    <Col xs={12} sm={12} md={12} lg={6} xl={5} className="mt-2">
                        <h2 >UTS Team</h2>
                            <div className="profile-card mt-4">
                                <img src={ProfileJoanImg} className="card-profile-img" />
                                <div className="profilecard-content">
                                    <p className="ms-3 mb-0 profilecard-name">Joan Francy</p>
                                    <p className="ms-3 mb- profilecard-title">President</p>  
                                </div>     
                            </div>
                            <div className="profile-card mt-4">
                                <img src={ProfileLisaImg} className="card-profile-img" />
                                <div className="profilecard-content">
                                    <p className="ms-3 mb-0 profilecard-name">Lisa DiPilato, PhD</p>
                                    <p className="ms-3 mb- profilecard-title">Content Creator</p>  
                                </div>     
                            </div>
                            <div className="profile-card mt-4">
                                <img src={ProfileChrisImg} className="card-profile-img" /> 
                                <div className="profilecard-content">
                                    <p className="ms-3 mb-0 profilecard-name">Chris Francy, PhD</p>
                                    <p className="ms-3 mb- profilecard-title">Content Creator</p>  
                                </div>     
                            </div>
                            <div className="profile-card mt-4">
                                <img src={ProfileHannhImg} className="card-profile-img" />
                                <div className="profilecard-content">
                                    <p className="ms-3 mb-0 profilecard-name">Hannah Melander, MBA </p>
                                    <p className="ms-3 mb- profilecard-title">Content Creator</p>  
                                </div>     
                            </div>
                            {/* <div className="profile-card mt-4">
                                <img src={ProfileKathImg} className="card-profile-img" />
                                <div className="profilecard-content">
                                    <p className="ms-3 mb-0 profilecard-name">Katherine Na</p>
                                    <p className="ms-3 mb- profilecard-title">Project Management</p>  
                                </div>     
                            </div> */}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} xl={5} className="mt-2" style={{marginLeft: "auto"}}>
                        <h2>Board of Directors</h2>
                            <div className="profile-card mt-4">
                                <img src={ProfileAmiImg} className="card-profile-img" />
                                <div className="profilecard-content">
                                    <p className="ms-3 mb-0 profilecard-name">Ami Ehlenberger</p>
                                    <p className="ms-3 mb-0 profilecard-title">Managing Director, CTO, </p>
                                    <p className="ms-3 mb-0 profilecard-title">Machine Learning & Intelligence</p>
                                    <p className="ms-3 mb-0 profilecard-title">Operations, Chase</p>     
                                </div>     
                            </div>
                            <div className="profile-card mt-4">
                                <img src={ProfileDadaImg} className="card-profile-img" />
                                <div className="profilecard-content">
                                    <p className="ms-3 mb-0 profilecard-name">Dada Honde</p>
                                    <p className="ms-3 mb-0 profilecard-title">Chief Technology Officer,</p>
                                    <p className="ms-3 mb-0 profilecard-title">Portnoff</p>  
                                </div>     
                            </div>
                    </Col>
                </Row>
            </div>
            ))}
            <div className="sponser-section">
                <Sponser />
            </div>
            <div className="sharemodal-section">
                <ShareModal show={showModal} handleClose={handleCloseModal} videourl={videourl} videoId={videoId} />
            </div>
        </Container>  
        </div>
    )
}   